export interface WebClientConfiguration {
  BILLING_API_BASE_URL: string;
  AUTH_API_BASE_URL: string;
  CLUSTER_API_BASE_URL: string;
  DELETE_MANAGER_API_BASE_URL: string;
}

const config: WebClientConfiguration = (window as any).BM_CONFIG;
if (!config) {
  throw new Error("Missing configuration. Please contact support.");
}

export default config;
