import React from "react";
import { Outlet } from "react-router-dom";
import {
  makeStyles,
  CssBaseline,
  Hidden,
  Typography,
  Link,
} from "@material-ui/core";
import Navigator from "../../components/Navigator";
import Header from "../../components/Header";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 256;

const useStyles = makeStyles({
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  app: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  main: {
    flex: 1,
    padding: "30px 16px",
    background: "#eaeff1",
  },
  footer: {
    padding: "16px 16px",
    background: "#eaeff1",
  },
});

function Dashboard() {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav className={classes.drawer}>
        <Hidden smUp implementation="js">
          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        </Hidden>
        <Hidden xsDown implementation="css">
          <Navigator PaperProps={{ style: { width: drawerWidth } }} />
        </Hidden>
      </nav>
      <div className={classes.app}>
        <Header onDrawerToggle={handleDrawerToggle} title="" />
        <main className={classes.main}>
          <Outlet />
        </main>
        <footer className={classes.footer}>
          <Copyright />
        </footer>
      </div>
    </div>
  );
}

export default Dashboard;
