import { Button, TextField, CircularProgress } from "@material-ui/core";
import { useState } from "react";
import useAuth from "../../context/useAuth";

interface Props {
  onSubmit(): void;
}

const LoginPage = () => {
  const { login, loading } = useAuth();
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();

  const handleSubmit = () => {
    login(email!, password!);
  };
  return (
    <div className="form-wrapper">
      <form className="form">
        <div className="form-group">
          <TextField
            fullWidth
            label="Email"
            type="email"
            variant="outlined"
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        <div className="form-group">
          <TextField
            fullWidth
            label="Password"
            type="password"
            variant="outlined"
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>
        {/* <div className="form-group mb-4">
          <div className="form-txt-wrap">
            <div className="form-txt">Forgot your password?</div>
          </div>
        </div> */}
        <div className="form-group btn-form-group">
          <Button
            className="w-100"
            color="primary"
            size="large"
            onClick={() => handleSubmit()}
            variant="contained"
            endIcon={loading && <CircularProgress size={14} />}
          >
            Sign in
          </Button>
        </div>
      </form>
    </div>
  );
};
export default LoginPage;
