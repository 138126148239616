import Axios from "axios";
import config from "../utils/config-loader";

const ORGANIZATION_ENDPOINT = `${config.AUTH_API_BASE_URL}/api/v1/organization`;

export interface DeleteOrganizationRequest {
  tagCode: string;
  organization_id: string;
  project_id: string[];
  delete_cluster_resources: boolean;
}

export interface DeleteProjectRequest {
  tagCode: string;
  organization_id: string;
  project_id: string;
}

export const deleteOrganization = async (organizationId: string) => {
  const res = await Axios.delete(`${ORGANIZATION_ENDPOINT}/${organizationId}`);
  return res.data;
};

export const deleteProject = async (projectId: string) => {
  const res = await Axios.delete(`${ORGANIZATION_ENDPOINT}/project/${projectId}`);
  return res.data;
};
