import Axios from "axios";
import config from "../utils/config-loader";

const CLUSTER_PRICE_ENDPOINT = `${config.BILLING_API_BASE_URL}/api/v1/cluster/pricing`;

export interface ClusterPricing {
  id: string;
  organization_id: string;
  organization_name: string;
  project_id: string;
  project_name: string;
  cluster_id: string;
  cluster_name: string;
  cluster_price: string;
  node_price: number;
  master_nodes: number;
  free_nodes: number;
  override: boolean;
}

interface Response<T> {
  message: string;
  success: boolean;
  data: T[];
}

export const createClusterPricingRecord = async (data: ClusterPricing) => {
  const res = await Axios.post(`${CLUSTER_PRICE_ENDPOINT}/create`, data);
  return res.data;
};

export const updateClusterPricingRecord = async ({
  data,
}: {
  data: Pick<
    ClusterPricing,
    | "cluster_id"
    | "cluster_price"
    | "node_price"
    | "master_nodes"
    | "free_nodes"
  >;
}) => {
  const res = await Axios.put(`${CLUSTER_PRICE_ENDPOINT}/update`, data);
  return res.data;
};

export const getClusterPricingById = async (recordId: string) => {
  const res = await Axios.get(`${CLUSTER_PRICE_ENDPOINT}/details/${recordId}`);
  return res.data;
};

export const deleteClusterPricingRecordById = async (
  recordId: string
): Promise<Response<string>> => {
  const res = await Axios.delete<Response<string>>(
    `${CLUSTER_PRICE_ENDPOINT}/delete/${recordId}`
  );
  return res.data;
};

export const fetchAllClusterPricingRecords = async (): Promise<
  Response<ClusterPricing>
> => {
  const res = await Axios.get(`${CLUSTER_PRICE_ENDPOINT}`);
  return res.data;
};
