import React, { useState } from "react";
import { Location } from "history";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Paper,
  Theme,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core";
import { ArrowBack as ArrowIcon } from "@material-ui/icons";

import ProjectTable from "./components/ProjectTable";

interface LocationState {
  organizationName: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: "auto",
    overflow: "hidden",
  },
  organizationName: {
    textTransform: "uppercase",
  },
  searchBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: "block",
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: "40px 16px",
  },
}));

const ProjectPage = () => {
  const classes = useStyles();
  const location = useLocation() as Location<LocationState>;
  const navigate = useNavigate();
  const [dialonOpen, setDialogOpen] = useState(false);
  const [searchText, setSearchInput] = useState("");

  const locationState = location.state;

  return (
    <>
      <div className={classes.paper}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <IconButton
            size="small"
            edge="end"
            style={{ padding: 10 }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowIcon fontSize="default" />
          </IconButton>
          <Typography
            className={classes.organizationName}
            variant="h5"
            gutterBottom
          >
            {locationState?.organizationName}
          </Typography>
        </div>

        <Paper>
          <ProjectTable />
        </Paper>
      </div>
    </>
  );
};

export default ProjectPage;
