// ts-nocheck
import MUIDataTable from "mui-datatables";
import {
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  ListItemIcon,
  CircularProgress,
} from "@material-ui/core";
import {
  CheckCircle as ActiveIcon,
  Cancel as InactiveIcon,
  Delete as DeleteIcon,
  MoreHoriz as MoreIcon,
  ArrowForward as ArrowIcon,
} from "@material-ui/icons";
import { useNavigate, useParams } from "react-router";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { format } from "date-fns";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { useSnackbar } from "notistack";

import LoadingSpinner from "../../../components/LoadingSpinner";
import DeleteConfirmation from "./DeleteConfirmation";
import {
  fetchOrganizationList,
  fetchProjectsForOrganization,
  Project,
} from "../../../api/form-data";
import { deleteProject } from "../../../api/delete-manager";
import { useState } from "react";

const ProjectTable = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [openDeleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState<Project>();

  const columns = [
    {
      name: "name",
      label: "Project Name",
      options: { filter: true, sort: true },
    },

    {
      name: "",
      label: "Actions",
      options: {
        customBodyRenderLite: (index: number) => {
          const project = projects?.data[index];
          return (
            <>
              <PopupState variant="popover" popupId="demoMenu">
                {(popupState) => (
                  <>
                    <IconButton
                      color="inherit"
                      size="small"
                      {...bindTrigger(popupState)}
                    >
                      <MoreIcon />
                    </IconButton>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        onClick={() => {
                          setSelectedProject(project);
                          setDeleteDialog(true);
                          popupState.close();
                        }}
                      >
                        <ListItemIcon>
                          {isLoading ? (
                            <CircularProgress size={14} />
                          ) : (
                            <DeleteIcon />
                          )}
                        </ListItemIcon>
                        Delete Project
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </PopupState>
            </>
          );
        },
      },
    },
  ];

  const { isLoading, data: projects } = useQuery(["projects", id], () =>
    fetchProjectsForOrganization(id)
  );

  const { mutate, isLoading: deletingProject } = useMutation(
    deleteProject,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["projects", id]);
        enqueueSnackbar(`project ${selectedProject?.name} deleted`, {
          variant: "info",
        });
      },
      onError: (err) => {
        enqueueSnackbar("Error occured while deleting project", {
          variant: "error",
        });
      },
    }
  );

  const handleDeletion = () => {
    const id = selectedProject?.id;
    mutate(id!);
  };

  return (
    <>
      <MUIDataTable
        title=""
        data={projects && projects.data ? projects.data : []}
        columns={columns}
        options={{
          download: false,
          print: false,
          textLabels: {
            body: {
              noMatch: isLoading ? (
                <LoadingSpinner text="" size={14} />
              ) : (
                "No projects found"
              ),
            },
          },
        }}
      ></MUIDataTable>
      {openDeleteDialog && (
        <DeleteConfirmation
          open={openDeleteDialog}
          handleClose={() => setDeleteDialog(false)}
          type="project"
          valueToMatch={selectedProject?.name!}
          validationFieldType="name"
          onConfirm={handleDeletion}
          loading={deletingProject}
        />
      )}
    </>
  );
};

export default ProjectTable;
