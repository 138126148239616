import React from "react";
import { AppBar, Button, Toolbar, Grid } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Add as AddIcon } from "@material-ui/icons";

export interface TableHeaderProps {
  setDialogOpen: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: 936,
      margin: "auto",
      overflow: "hidden",
    },
    searchBar: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: "block",
    },
    addUser: {
      marginRight: theme.spacing(1),
    },
    contentWrapper: {
      margin: "40px 16px",
    },
    toolbar: {
      paddingRight: 12,
    },
  })
);

const TableHeader = ({ setDialogOpen }: TableHeaderProps) => {
  const classes = useStyles();
  return (
    <>
      <AppBar
        className={classes.searchBar}
        position="static"
        color="default"
        elevation={0}
      >
        <Toolbar className={classes.toolbar}>
          <Grid container spacing={2} direction="row" alignItems="flex-end">
            <Grid item>
              <Tooltip title="Reload">
                <Button
                  variant="text"
                  disableElevation
                  size="medium"
                  color="primary"
                  onClick={() => {
                    setDialogOpen();
                  }}
                  startIcon={<AddIcon />}
                >
                  NEW RECORD
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};
export default TableHeader;
