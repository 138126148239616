import Axios from "axios";
import config from "../utils/config-loader";

const ORG_ENDPOINT = `${config.AUTH_API_BASE_URL}/api/v1/organization`;
const CLUSTER_ENDPOINT = `${config.CLUSTER_API_BASE_URL}/api/v1/cluster`;

export interface Organization {
  organization_id: string;
  name: string;
  active: boolean;
  created_date: string;
  id: string;
  domain: string;
}

export interface Project {
  id: string;
  name: string;
}

export interface Cluster {
  name: string;
  id: string;
}

export interface Response<T> {
  success: boolean;
  data: T[];
}

export interface OrganizationUser {
  username: string;
  email: string;
  id: string;
}

export const fetchOrganizationList = async ({
  userId = "all",
}: {
  userId?: string;
}): Promise<Response<Organization>> => {
  const res = await Axios.get<Response<Organization>>(
    `${ORG_ENDPOINT}/userorg/${userId}`
  );
  return res.data;
};

export const fetchProjectsForOrganization = async (
  organizationId: string
): Promise<Response<Project>> => {
  const res = await Axios.get<Response<Project>>(
    `${ORG_ENDPOINT}/project/${organizationId}`
  );
  return res.data;
};

export const fetchClustersForProjectAndOrganization = async (
  organizationId: string,
  projectId: string
): Promise<Cluster[]> => {
  const res = await Axios.get<Cluster[]>(
    `${CLUSTER_ENDPOINT}/?project_id=${projectId}&organization_id=${organizationId}`
  );
  return res.data;
};

export const fetchAllUsers = async (): Promise<Response<OrganizationUser>> => {
  const res = await Axios.get<Response<OrganizationUser>>(
    `${ORG_ENDPOINT}/user/all`
  );

  return res.data;
};
