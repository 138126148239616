import Axios from "axios";
import config from "../utils/config-loader";

const AUTH_ENDPOINT = `${config.AUTH_API_BASE_URL}/api/v1/user`;

export interface TokenResponse {
  id_token: string;
  refresh_token: string;
}

export interface User {
  userId: string;
  email: string;
  name: string;
  image: string;
}

export interface Response<T> {
  success: boolean;
  data: T;
  message: string;
}

export const login = async (data: {
  email: string;
  password: string;
}): Promise<Response<TokenResponse>> => {
  const res = await Axios.post<Response<TokenResponse>>(`${AUTH_ENDPOINT}/login`, data);
  return res.data;
};

export const validateToken = async (data: {
  id_token: string;
  refreshToken: string;
}) => {
  const res = await Axios.post<Response<TokenResponse>>(
    `${AUTH_ENDPOINT}/validate`,
    data
  );

  return res.data;
};
