import {
  jssPreset,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core/styles";
import { create } from "jss";
import React from "react";
import { theme } from ".";

const jss = create({ plugins: [...jssPreset().plugins] });

function CustomThemeProvider(props: React.PropsWithChildren<{}>) {
  const { children } = props;
  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>{children}</StylesProvider>
    </ThemeProvider>
  );
}

export default CustomThemeProvider;
