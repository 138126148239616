import { IconButton, Link as MLink, Snackbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PlatformerLogo from "../../assets/img/logo/platformer-logo.png";

import React from "react";
import LoginForm from "../../modules/Authentication/LoginForm";
import "../../scss/LoginPage.scss";
export function LoginPage() {
  return (
    <div className="authpage-container">
      <div className="authpage-wrapper">
        <div className="pl-logo-wrap">
          <img src={PlatformerLogo} className="pl-logo" alt="Platformer Logo" />
        </div>
        <div className="auth-form-container">
          <div className="form-container">
            <div className="auth-form login-form">
              <div className="form-cont">
                <div className="form-wrap">
                  <div className="form-head">
                    <h3 className="title">Sign in to your account</h3>
                  </div>
                  <div className="form-body">
                    <LoginForm />
                  </div>
                  <div className="form-footer">
                    <div className="form-txt-wrap"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="auth-page-footer">
          <small>
            &copy; {new Date().getFullYear()} Platformer Cloud Pty Ltd. All
            Rights Reserved
          </small>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
