import { Navigate } from "react-router-dom";
import { PartialRouteObject } from "react-router";
import DashboardLayout from "./layout/DashboardLayout";
import ClusterBilling from "./modules/ClusterBilling";
import Organizations from "./modules/Organization/OrganizationPage";
import OrganizationProjects from "./modules/Organization/ProjectPage";
import AuthLayout from "./layout/AuthLayout";
import LoginPage from "./modules/Authentication/LoginForm";

const routes = (loggedIn: boolean, userId: string) => [
  {
    path: "app",
    element: loggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      {
        path: "cluster-billing",
        element: <ClusterBilling />,
      },
      { path: "organizations", element: <Organizations /> },
      { path: "organizations/:id/project", element: <OrganizationProjects /> },
    ],
  },
  {
    path: "/",
    element: !loggedIn ? (
      <AuthLayout />
    ) : (
      <Navigate to={`/app/organizations`} />
    ),
    children: [{ path: "login", element: <LoginPage /> }],
  },
];

export default routes;
