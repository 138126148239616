// @ts-nocheck
import MUIDataTable from "mui-datatables";
import { IconButton } from "@material-ui/core";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { Edit, Delete } from "@material-ui/icons";

import LoadingSpinner from "../../../components/LoadingSpinner";
import {
  deleteClusterPricingRecordById,
  fetchAllClusterPricingRecords,
} from "../../../api/cluster-pricing";

const columns = [
  {
    name: "organization_name",
    label: "Organization",
    options: { filter: true, sort: true },
  },
  {
    name: "project_name",
    label: "Project",
    options: { filter: true, sort: true },
  },
  {
    name: "cluster_name",
    label: "Cluster",
    options: { filter: true, sort: true },
  },
  {
    name: "cluster_price",
    label: "Cluster Price",
    options: { sort: true, filter: false },
  },
  {
    name: "node_price",
    label: "Node Price",
    options: { sort: true, filter: false },
  },
  {
    name: "master_nodes",
    label: "Master Nodes",
    options: { sort: true, filter: false },
  },
  {
    name: "free_nodes",
    label: "Free Nodes",
    options: { sort: true, filter: false },
  },
  {
    name: "",
    label: "Actions",
    options: {
      customBodyRenderLite: (index: number) => {
        return (
          <>
            <IconButton>
              <Edit />
            </IconButton>
          </>
        );
      },
    },
  },
];

const BillingRecordTable = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { isLoading: recordsIsLoading, data: records } = useQuery(
    "pricing-records",
    fetchAllClusterPricingRecords
  );

  const {
    data: deleteResponse,
    mutate,
    isFetching,
  } = useMutation(deleteClusterPricingRecordById, {
    onSuccess: async () => {
      await queryClient.invalidateQueries("pricing-records");
      enqueueSnackbar("Pricing record deleted", { variant: "info" });
    },
    onError: (err: Error) => {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    },
  });

  const handleRowDelete = (data) => {
    const [deleteRecord] = data;

    const { id } = records?.data[deleteRecord.index];

    mutate(id);

    return deleteResponse?.success;
  };

  return (
    <MUIDataTable
      data={records && records.data ? records.data : []}
      columns={columns}
      options={{
        download: false,
        print: false,
        onRowsDelete: ({ data }) => handleRowDelete(data),
        textLabels: {
          body: {
            noMatch:
              isFetching || recordsIsLoading ? (
                <LoadingSpinner text="" size={14} />
              ) : (
                "No procedures found"
              ),
          },
        },
      }}
    ></MUIDataTable>
  );
};

export default BillingRecordTable;
