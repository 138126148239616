// ts-nocheck
import { useState } from "react";
import MUIDataTable from "mui-datatables";
import {
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  ListItemIcon,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import {
  CheckCircle as ActiveIcon,
  Cancel as InactiveIcon,
  Delete as DeleteIcon,
  MoreHoriz as MoreIcon,
  ArrowForward as ArrowIcon,
} from "@material-ui/icons";

import { useNavigate } from "react-router";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { format } from "date-fns";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { useSnackbar } from "notistack";

import LoadingSpinner from "../../../components/LoadingSpinner";
import DeleteConfirmation from "./DeleteConfirmation";
import {
  fetchOrganizationList,
  fetchProjectsForOrganization,
  Organization,
} from "../../../api/form-data";
import {
  deleteOrganization,
  DeleteOrganizationRequest,
} from "../../../api/delete-manager";

import useAuth from "../../../context/useAuth";

export interface OrganizationTableProps {
  selectedUser: any;
}

const OrganizationTable = ({ selectedUser }: OrganizationTableProps) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [openDeleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization>();

  const columns = [
    {
      name: "created_date",
      label: "Created On",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: string) => {
          return format(new Date(value), "d/M/yyyy");
        },
      },
    },
    { name: "name", label: "Name", options: { filter: true, sort: true } },
    {
      name: "org_active",
      label: "Active",
      options: {
        filter: true,
        customBodyRenderLite: (index: number) => {
          const organization: Organization = organizations?.data[index]!;
          return organization?.active ? <ActiveIcon /> : <InactiveIcon />;
        },
      },
    },
    {
      name: "",
      label: "Actions",
      options: {
        customBodyRenderLite: (index: number) => {
          const organization = organizations?.data[index];
          return (
            <>
              <PopupState variant="popover" popupId="demoMenu">
                {(popupState) => (
                  <>
                    <IconButton
                      color="inherit"
                      size="small"
                      {...bindTrigger(popupState)}
                    >
                      <MoreIcon />
                    </IconButton>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        onClick={() => {
                          setSelectedOrganization(organization);
                          setDeleteDialog(true);
                          popupState.close();
                        }}
                      >
                        <ListItemIcon>
                          <DeleteIcon />
                        </ListItemIcon>
                        Delete Organization
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </PopupState>
            </>
          );
        },
      },
    },
    {
      name: "",
      label: "Projects",
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        setCellProps: () => ({
          style: {
            textAlign: "right",
            width: "10px",
          },
        }),
        customBodyRenderLite: (index: number) => {
          const organization = organizations?.data[index]!;
          return (
            <Tooltip title="View projects in organization">
              <IconButton
                size="small"
                edge="end"
                color="primary"
                onClick={() => {
                  navigate(`${organization.id}/project`, {
                    state: { organizationName: organization.name },
                  });
                }}
              >
                <ArrowIcon fontSize="default" />
              </IconButton>
            </Tooltip>
          );
        },
      },
    },
  ];

  const { isLoading, data: organizations } = useQuery(
    ["organizations", selectedUser?.value],
    () => fetchOrganizationList({ userId: selectedUser?.value }),
    { enabled: selectedUser ? true : false }
  );

  const { mutate, isLoading: deletingOrganization } = useMutation(
    deleteOrganization,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          "organizations",
          selectedUser?.value,
        ]);
        enqueueSnackbar(`Organization ${selectedOrganization?.name} deleted`, {
          variant: "info",
        });
        setDeleteDialog(false);
      },
      onError: (err) => {
        enqueueSnackbar("Error occured while deleting organization", {
          variant: "error",
        });
      },
    }
  );

  const handleDeletion = () => {
    const id = selectedOrganization?.id;
    mutate(id!);
  };

  return (
    <>
      <MUIDataTable
        title=""
        data={organizations && organizations.data ? organizations.data : []}
        columns={columns}
        options={{
          download: false,
          print: false,

          textLabels: {
            body: {
              noMatch: isLoading ? (
                <LoadingSpinner text="" size={14} />
              ) : (
                "No organizations found"
              ),
            },
          },
        }}
      ></MUIDataTable>
      {openDeleteDialog && (
        <DeleteConfirmation
          open={openDeleteDialog}
          handleClose={() => setDeleteDialog(false)}
          type="organization"
          valueToMatch={selectedOrganization?.name!}
          validationFieldType="name"
          onConfirm={handleDeletion}
          loading={deletingOrganization}
        />
      )}
    </>
  );
};

export default OrganizationTable;
