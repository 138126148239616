import React from "react";
import clsx from "clsx";

import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

import {
  Divider,
  Drawer,
  DrawerProps,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  makeStyles,
} from "@material-ui/core";

import {
  Receipt as ReceiptIcon,
  Home as HomeIcon,
  AccountBalance as OrganizationIcon,
} from "@material-ui/icons";

import { Omit } from "@material-ui/types";

const categories = [
  {
    id: "Main",
    children: [
      {
        id: "Organization",
        icon: <OrganizationIcon />,
        active: true,
        path: "/app/organizations",
      },
      {
        id: "Cluster Billing",
        icon: <ReceiptIcon />,
        active: false,
        path: "/app/cluster-billing",
      },
    ],
  },
  {
    id: "Logs",
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 3,
    paddingBottom: 3,
    color: "rgba(255, 255, 255, 0.7) ",
    "&:hover,&:focus": {
      backgroundColor: "rgba(255, 255, 255, 0.08)",
    },
  },
  itemCategory: {
    backgroundColor: "#000f21",
    boxShadow: "0 -1px 0 #101010 inset",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    backgroundColor: "#043d94 !important",
    color: "#d4d4d4 !important",
  },
  itemPrimary: {
    fontSize: "inherit",
    color: "inherit",
  },
  itemIcon: {
    minWidth: "auto",
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    backgroundColor: "#093271",
  },
}));

export interface NavigatorProps extends Omit<DrawerProps, "classes"> {}

function Navigator({ PaperProps }: NavigatorProps) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Drawer variant="permanent" PaperProps={PaperProps}>
      <List disablePadding>
        <ListItem
          className={clsx(classes.firebase, classes.item, classes.itemCategory)}
        >
          PROJECT X
        </ListItem>
        <ListItem className={clsx(classes.item, classes.itemCategory)}>
          <ListItemIcon className={classes.itemIcon}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            Dashboard
          </ListItemText>
        </ListItem>
        {categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children?.map(({ id: childId, icon, path }) => {
              const isActive = location.pathname === path;
              return (
                <ListItem
                  key={childId}
                  button
                  className={clsx(
                    classes.item,
                    isActive && classes.itemActiveItem
                  )}
                  onClick={() => navigate(path)}
                >
                  <ListItemIcon className={classes.itemIcon}>
                    {icon}
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  >
                    {childId}
                  </ListItemText>
                </ListItem>
              );
            })}
            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

export default Navigator;
