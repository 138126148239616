import { useState } from "react";

import { Paper, Theme, makeStyles, Typography } from "@material-ui/core";

import TableHeader from "./components/TableHeader";
import AddOrEditClusterBillingDialog from "./components/AddOrEditClusterBillingDialog";
import ClusterBillingTable from "./components/BillingRecordTable";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: "auto",
    overflow: "hidden",
  },
  searchBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: "block",
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: "40px 16px",
  },
}));

const ClusterBilling = () => {
  const classes = useStyles();
  const [dialonOpen, setDialogOpen] = useState(false);
  const [searchText, setSearchInput] = useState("");

  return (
    <>
      {dialonOpen && (
        <AddOrEditClusterBillingDialog
          handleClose={() => setDialogOpen(false)}
        />
      )}
      <Paper className={classes.paper}>
        <TableHeader setDialogOpen={() => setDialogOpen(true)} />
        <div className="table-box">
          <ClusterBillingTable />
        </div>
      </Paper>
    </>
  );
};

export default ClusterBilling;
