import { Box, CircularProgress, Typography } from '@material-ui/core';

const LoadingSpinner = ({
  text,
  className,
  size
}: {
  text?: string;
  className?: string;
  size?: number;
}) => {
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      display="flex"
      height="100%"
      className={className}
    >
      {text && (
        <Typography variant="h5" style={{ marginRight: '1rem' }}>
          {text}
        </Typography>
      )}
      <CircularProgress size={size || 16} />
    </Box>
  );
};

export default LoadingSpinner;
