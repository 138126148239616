import { AppBar, Button, Toolbar, Grid, Typography } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import Select from "react-select";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { OrganizationUser } from "../../../api/form-data";
import { useNavigate } from "react-router-dom";

export interface UserFilterTableHeaderProps {
  selectedUser: any;
  setSelectedUser: any;
  userList: OrganizationUser[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: 936,
      margin: "auto",
      overflow: "hidden",
    },
    searchBar: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: "block",
    },
    addUser: {
      marginRight: theme.spacing(1),
    },
    contentWrapper: {
      margin: "40px 16px",
    },
    toolbar: {
      paddingRight: 12,
    },
  })
);

const UserFilterTableHeader = ({
  selectedUser,
  userList,
  setSelectedUser,
}: UserFilterTableHeaderProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <>
      <AppBar
        className={classes.searchBar}
        position="static"
        color="default"
        elevation={0}
      >
        <Toolbar className={classes.toolbar}>
          <Grid container spacing={2} direction="row" alignItems="center" >
            <Grid item >
              <Typography>User :</Typography>
            </Grid>
            <Grid item xs={3} style={{ padding: 10 }}>
              <Select
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                inputId="cluster"
                value={selectedUser}
                isDisabled={false}
                isLoading={false}
                options={
                  userList &&
                  userList.map(({ username, id, email }) => {
                    return { value: id, label: username ? username : email };
                  })
                }
                onChange={(selectedOption) => {
                  if (!selectedOption) {
                    navigate("", { replace: true });
                    return;
                  }
                  const { label, value } = selectedOption;
                  console.log("label", value);
                  navigate(`?userId=${value}`, { replace: true });
                  setSelectedUser({ label, value });
                }}
                isClearable
                placeholder="Select User"
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};
export default UserFilterTableHeader;
