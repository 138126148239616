import { useRoutes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { SnackbarProvider } from "notistack";
import Axios from "axios";
import "./App.scss";
import routes from "./routes";
import ThemeProvider from "./theme/ThemeProvider";
import useAuth from "./context/useAuth";

// Axios.defaults.headers.common[
//   "authorization"
// ] = `eyJhbGciOiJSUzI1NiIsImtpZCI6ImFiMGNiMTk5Zjg3MGYyOGUyOTg5YWI0ODFjYzJlNDdlMGUyY2MxOWQiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQWNoYWxhIERpYXMiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EtL0FPaDE0R2dBRVZMUFE2YzVla3AxVmw5QUNaaXF1TV9hWkRxb202X0dqaUJ3IiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL3BsYXRmb3JtZXItMTU3OTc4NTUzMjc5Ny1oODQzaCIsImF1ZCI6InBsYXRmb3JtZXItMTU3OTc4NTUzMjc5Ny1oODQzaCIsImF1dGhfdGltZSI6MTYyMzM0ODc0NSwidXNlcl9pZCI6ImVydGhQNW82V1FTUHZ0OG9ISjd1NFA5R0FhTDIiLCJzdWIiOiJlcnRoUDVvNldRU1B2dDhvSEo3dTRQOUdBYUwyIiwiaWF0IjoxNjIzMzQ4NzQ1LCJleHAiOjE2MjMzNTIzNDUsImVtYWlsIjoiZGlhc3Bvc2l0aXZlQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7Imdvb2dsZS5jb20iOlsiMTE3MjM1Mjc4NjIyMjIyNjE0NTc2Il0sImVtYWlsIjpbImRpYXNwb3NpdGl2ZUBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJjdXN0b20ifX0.ojy-60sJ3K-wvlAp55I9EXgSbpv6r7kF-vZ42k1IbS8tmcCAQQMrrx8GiUmj0W5MqBfs_xxwRedOdptYS2JCzPkFqjvLZjYTfpAMTcUhl9e9CIOR7ARSWccKNcFgwvg1toO89J1Yji09ilFXNiyh0v1Sepy7LbaVCIqWGP7gkAy_2JRweP1ahvZ8CnwF9FB2HNxQDVpCxv4Crt65eNAoJA8wW1RVmK1J5BvidKUxB7lXV0shchL2vD2dpCGFUqvJ2tRzWrY8VQ4dcsUQDsTVxHNje_DwUdMKcu3uhmSkn4xfnJy_71oTbilKE_m_sF9xVGfOqneTUcW-hqTctYDYlA`;

const queryClient = new QueryClient();

function App() {
  const { loggedIn, user } = useAuth();

  const routing = useRoutes(routes(loggedIn, user?.userId!));

  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider>
        <ThemeProvider>{routing}</ThemeProvider>
      </SnackbarProvider>
    </QueryClientProvider>
  );
}

export default App;
