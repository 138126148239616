import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { Paper, Theme, makeStyles } from "@material-ui/core";
import queryString from "querystring";
import useAuth from "../../context/useAuth";
import OrganizationTable from "./components/OrganizationTable";
import UserFilterTableHeader from "./components/UserFilterTableHeader";

import { fetchAllUsers } from "../../api/form-data";
import { parse } from "path";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: "auto",
    overflow: "hidden",
  },
  searchBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: "block",
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: "40px 16px",
  },
}));

const OrganizationPage = () => {
  const classes = useStyles();
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [dialonOpen, setDialogOpen] = useState(false);
  const [searchText, setSearchInput] = useState("");
  const [selectedUser, setSelectedUser] = useState<{
    value: string;
    label: string;
  }>({ value: user?.userId!, label: user?.name! });

  const { isLoading, data: users } = useQuery("users", () => fetchAllUsers(), {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const parsed = queryString.parse(location.search.slice(1));
    const user = users?.data.find((user) => user.id === parsed.userId);
    setSelectedUser({ value: user?.id!, label: user?.username! });
  }, [location.search]);

  useEffect(() => {
    if (!selectedUser) {
      navigate(`?userId=${user?.userId}`, { replace: true });
    }
  }, [user]);

  return (
    <>
      <Paper className={classes.paper}>
        <UserFilterTableHeader
          selectedUser={selectedUser}
          userList={users?.data!}
          setSelectedUser={setSelectedUser}
        />
        <OrganizationTable selectedUser={selectedUser} />
      </Paper>
    </>
  );
};

export default OrganizationPage;
