import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  CircularProgress
} from "@material-ui/core";

export interface DeleteDialogProps {
  open: boolean;
  handleClose: () => void;
  type: string;
  valueToMatch: string;
  validationFieldType: string;
  additionalText?: string;
  onConfirm: () => void;
  loading:boolean;
}

const DeleteAlert = ({
  open,
  handleClose,
  type,
  valueToMatch,
  validationFieldType,
  onConfirm,
  additionalText,
  loading
}: DeleteDialogProps) => {
  const [input, setInput] = useState<string>();
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          {additionalText && <Typography>{additionalText}</Typography>}
          <Typography variant="body1" gutterBottom>
            {`Are you sure you want to delete  ${type}`}
            <span
              style={{ fontWeight: "bold", color: "black" }}
            >{` ${valueToMatch}`}</span>
          </Typography>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
          <TextField
            variant="outlined"
            fullWidth
            label={`Please enter the ${type} ${validationFieldType} to confirm`}
            value={input}
            onChange={(e: React.SyntheticEvent) =>
              setInput((e.target as HTMLInputElement).value)
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button
            color="primary"
            disabled={input !== valueToMatch || loading}
            onClick={() => onConfirm()}
            autoFocus
            endIcon={loading && <CircularProgress size={14} />}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteAlert;
