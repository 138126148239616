import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import jwtDecode from "jwt-decode";
import { login as loginUser, validateToken, User } from "../api/authentication";
import Axios from "axios";

interface AuthContextTypes {
  login: (email: string, password: string) => void;
  logout: () => void;
  loading: boolean;
  loggedIn: boolean;
  error: string | undefined;
  // Replace this with user object
  user: User | undefined;
}

export interface TokenPayload {
  user_id: string;
  email: string;
  name: string;
  picture: string;
}

const AuthContext = createContext<AuthContextTypes>({} as AuthContextTypes);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [user, setUser] = useState<User>();

  const login = async (email: string, password: string) => {
    setLoading(true);

    const { data, success } = await loginUser({ email, password });

    if (success) {
      const { id_token, refresh_token } = data;
      localStorage.setItem("id", id_token);
      const tokenResponse = await validateToken({
        id_token,
        refreshToken: "",
      });
      if (tokenResponse.success) {
        const {
          data: { id_token },
        } = tokenResponse;
        setLoggedIn(true);
        const { name, user_id, email, picture }: TokenPayload =
          jwtDecode(id_token);
        setUser({ name, userId: user_id, email, image: picture });

        Axios.defaults.headers.common["authorization"] = id_token;
      } else {
        setError(tokenResponse.message);
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("id");
    if (!token) {
      setLoggedIn(false);
      return;
    }

    // TODO: Check the exp and then get a new token

    const getNewToken = async () => {
      const tokenResponse = await validateToken({
        id_token: token,
        refreshToken: "",
      });
      if (tokenResponse.success) {
        const {
          data: { id_token },
        } = tokenResponse;
        setLoggedIn(true);
        const { name, user_id, email, picture }: TokenPayload =
          jwtDecode(id_token);
        setUser({ name, userId: user_id, email, image: picture });

        Axios.defaults.headers.common["authorization"] = id_token;
      } else {
        alert("Session expired. Please login to continue");
        setLoggedIn(false);
        setError(tokenResponse.message);
      }
    };

    getNewToken();

  }, []);

  const logout = () => {
    setLoggedIn(false);
    Axios.defaults.headers.common["authorization"] = null;
  };

  // Need to handle token via the useEffect , so it will persist

  // add a useMemo to prevent re-rendering the tree

  return (
    <AuthContext.Provider
      value={{ login, loggedIn, loading, error, logout, user }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default function useAuth() {
  return useContext(AuthContext);
}
