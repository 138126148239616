import {
  createMuiTheme,
  Theme as MuiTheme,
  ThemeOptions,
} from "@material-ui/core";
import overrides from "./overrides";
import palette from "./palette";
import typography from "./typography";

const baseTheme: ThemeOptions = {
  // palette,
  // typography: typography as any,
  // overrides,
  palette: {
    primary: {
      light: "#63ccff",
      main: "#043d94",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 5,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
};
let createdTheme = createMuiTheme(baseTheme);

createdTheme = {
  ...createdTheme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#00173a",
      },
    },
    MuiButton: {
      label: {
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none",
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: createdTheme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: createdTheme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
        margin: "0 16px",
        minWidth: 0,
        padding: 0,
        [createdTheme.breakpoints.up("md")]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: createdTheme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#404854",
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: createdTheme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        marginRight: 0,
        "& svg": {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};
export const theme = createdTheme;

type MuiPalette = MuiTheme["palette"];
interface Palette extends MuiPalette {
  icon: string;
}
export interface Theme extends MuiTheme {
  palette: Palette;
}
