// @ts-nocheck
import { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  makeStyles,
  Button,
  DialogActions,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import Select from "react-select";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import {
  ClusterPricing,
  createClusterPricingRecord,
} from "../../../api/cluster-pricing";

import {
  fetchOrganizationList,
  fetchProjectsForOrganization,
  fetchClustersForProjectAndOrganization,
} from "../../../api/form-data";

interface Props {
  handleClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  textFieldRow: {
    marginTop: theme.spacing(4),
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    marginTop: theme.spacing(4),
  },
}));

const AddorEditBillingDialog = ({ handleClose }: Props) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [organization, setOrganization] = useState([]);
  const [orgIsLoading, setOrgIsLoading] = useState(false);
  const [selectedProject, setSelectedProject] = useState();
  const [projects, setProjects] = useState([]);
  const [projectIsLoading, setProjectIsLoading] = useState(false);
  const [selectedCluster, setSelectedCluster] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [clusterIsLoading, setClusterIsLoading] = useState(false);
  const [clusterPrice, setClusterPrice] = useState(0);
  const [nodePrice, setNodePrice] = useState(0);
  const [masterNodes, setMasterNodes] = useState(1);
  const [freeNodes, setFreeNodes] = useState(0);
  const [formErrors, setFormErrors] = useState([]);

  const { mutate, isLoading } = useMutation(createClusterPricingRecord, {
    onSuccess: async () => {
      await queryClient.invalidateQueries("pricing-records");
      enqueueSnackbar("Cluster pricing record created", { variant: "success" });
      handleClose();
    },
    onError: (err: Error) => {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    },
  });

  const fetchOrganization = useCallback(async () => {
    setOrgIsLoading(true);
    const res = await fetchOrganizationList();
    setOrganization(res.data);
    setOrgIsLoading(false);
  }, []);

  useEffect(() => {
    fetchOrganization();
  }, [fetchOrganization]);

  const handleOrganizationIdChange = async (selected) => {
    setProjectIsLoading(true);
    setSelectedProject(null);
    setSelectedOrganization(selected);
    const res = await fetchProjectsForOrganization(selected.value);
    setProjects(res.data);
    setProjectIsLoading(false);
  };

  const handleProjectIdChange = async (selected) => {
    setClusterIsLoading(true);
    setSelectedCluster(null);
    setSelectedProject(selected);
    const data = await fetchClustersForProjectAndOrganization(
      selectedOrganization.value,
      selected.value
    );

    if (!data.error) {
      setClusters(data);
    } else {
      alert("Something went wrong");
    }

    setClusterIsLoading(false);
  };

  const onSubmit = () => {
    const formErrors = [];
    setFormErrors([]);
    if (!selectedOrganization)
      formErrors.push("OrganizationId can not be null");

    if (!selectedProject) formErrors.push("ProjectId can not be null");

    if (!selectedCluster) formErrors.push("ClusterId can not be null");

    if (clusterPrice < 1 && nodePrice < 1)
      formErrors.push("Cluster price or Node Price must be added");

    if (formErrors.length > 0) {
      setFormErrors(formErrors);
      return;
    }

    const requestObject: ClusterPricing = {
      organization_id: selectedOrganization.value,
      project_id: selectedProject.value,
      cluster_id: selectedCluster.value,
      cluster_name: selectedCluster.label,
      cluster_price: Number(clusterPrice),
      node_price: Number(nodePrice),
      master_nodes: Number(masterNodes),
      free_nodes: Number(freeNodes),
      override: false,
    };

    mutate(requestObject);
  };

  return (
    <Dialog open fullWidth>
      <DialogTitle id="form-dialog-title">Cluster Pricing Record</DialogTitle>
      <DialogContent>
        <div style={{ padding: 8 }}>
          {formErrors &&
            formErrors.map((err) => (
              <Typography variant="subtitle1" color="error">
                {err}
              </Typography>
            ))}
          <form>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <label>Organization</label>
              </Grid>
              <Grid item xs={9}>
                <Select
                  value={selectedOrganization}
                  inputId="organization"
                  isDisabled={orgIsLoading}
                  isLoading={orgIsLoading}
                  options={
                    organization &&
                    organization.map((or) => {
                      return { value: or.organization_id, label: or.name };
                    })
                  }
                  placeholder="Organization"
                  onChange={(selected) => handleOrganizationIdChange(selected)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <label>Project</label>
              </Grid>
              <Grid item xs={9}>
                <Select
                  value={selectedProject}
                  inputId="project"
                  isDisabled={!projects || !selectedOrganization}
                  isLoading={projectIsLoading}
                  options={
                    projects &&
                    projects.map((or) => {
                      return { value: or.projectId, label: or.projectName };
                    })
                  }
                  placeholder="Project"
                  onChange={(selected) => handleProjectIdChange(selected)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <label>Cluster</label>
              </Grid>
              <Grid item xs={9}>
                <Select
                  inputId="cluster"
                  value={selectedCluster}
                  isDisabled={!selectedProject}
                  isLoading={clusterIsLoading}
                  options={
                    clusters &&
                    clusters.map((cr) => {
                      return { value: cr.id, label: cr.name };
                    })
                  }
                  placeholder="Cluster"
                  onChange={(selected) => setSelectedCluster(selected)}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              style={{ justifyContent: "center" }}
              className={classes.textFieldRow}
            >
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Cluster Price ($)"
                  type="number"
                  variant="outlined"
                  value={clusterPrice}
                  onChange={(event) => setClusterPrice(event.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Node Price ($)"
                  type="number"
                  variant="outlined"
                  value={nodePrice}
                  onChange={(event) => setNodePrice(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.textFieldRow}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Master Nodes"
                  type="number"
                  variant="outlined"
                  value={masterNodes}
                  onChange={(event) => setMasterNodes(event.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Free Nodes"
                  type="number"
                  variant="outlined"
                  value={freeNodes}
                  onChange={(event) => setFreeNodes(event.target.value)}
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Button
                variant="outlined"
                color="primary"
                disabled={isLoading}
                onClick={() => onSubmit()}
                endIcon={isLoading && <CircularProgress size={14} />}
              >
                Submit
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
            </DialogActions>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddorEditBillingDialog;
